const html = document.documentElement;

// An empty listener changes Safari behavior to activate CSS :hover on touch.
html.addEventListener("touchstart", () => {});

// On end of a touch, shift focus to html to make sure the element no longer shows as hovered.
html.addEventListener("touchend", () => {
	html.focus();
});
html.addEventListener("touchcancel", () => {
	html.focus();
});
