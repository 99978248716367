import { fetcher } from "@chariz/utils";

const cookieNotice = document.querySelector(".toast-cookie-notice");
if (cookieNotice !== null) {
	const footerCookieConsentContainer = document.querySelector(".footer-cookie-consent-container") as HTMLDivElement;
	if (footerCookieConsentContainer) {
		footerCookieConsentContainer.style.display = "none";
	}

	const handler = (accept: boolean) => async (e: Event) => {
		e.preventDefault();

		// Animate out
		cookieNotice.classList.add("hiding");
		document.documentElement.dataset.cookieConsent = accept ? "true" : "false";

		// Initialise Sentry if we have permission.
		if (accept) {
			window.sentryInit?.();
		}

		try {
			await fetcher("post", "/auth/session/cookie-consent", { accept });
			if (footerCookieConsentContainer) {
				footerCookieConsentContainer.style.display = "";
			}
			setTimeout(() => cookieNotice.remove(), 1000);
		} catch {
			// Give the user a heads-up on why they might keep getting the cookie prompt, then auto hide.
			cookieNotice.classList.remove("hiding");

			const error = document.createElement("span");
			error.className = "small";
			error.setAttribute("role", "alert");
			error.textContent = `Couldn’t save your cookie preference because an error occurred. An ad-blocking extension may have blocked this. If you keep seeing this prompt, you may need to adjust your ad-blocking settings.`;

			const div = cookieNotice.querySelector(".alert > div")!;
			div.replaceWith(error);

			setTimeout(() => {
				cookieNotice.classList.add("hiding");
				setTimeout(() => cookieNotice.remove(), 1000);
			}, 15_000);
		}
	};

	cookieNotice.querySelector("#cookie-notice-accept")
		?.addEventListener("click", handler(true));
	cookieNotice.querySelector("#cookie-notice-decline")
		?.addEventListener("click", handler(false));
}
