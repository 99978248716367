$(() => {
	// Click to toggle any <time> that has relative/absolute variants.
	const times = $("time[title]")
		.parent();
	for (let i = 0; i < times.length; i++) {
		const item = times.eq(i);
		item.on("click", (e) => {
			e.preventDefault();
			const innerTimes = item.find("time[title]");
			for (let j = 0; j < innerTimes.length; j++) {
				const time = innerTimes.eq(j);
				const title = time.attr("title");
				const text = time.text();
				time.attr("title", text)
					.text(title!);
			}
		});
	}
});
