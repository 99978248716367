import { CharizURL } from "@chariz/utils";
import Dialog from "../shared/Dialog";
import Icon from "../shared/Icon";

export default function LoginDialog() {
	const location = window.location;
	const returnTo = location.href.slice(
		Math.max(
			0,
			location.protocol.length +
				location.hostname.length +
				(location.port.length > 0 ? location.port.length + 1 : 0) +
				2
		)
	);

	return (
		<Dialog bottomSheet id="login-dialog">
			<div className="modal-body">
				<button className="btn modal-close-btn d-sm-none" onClick={() => Dialog.hide(true)}>
					<span className="visually-hidden">Cancel</span>
					<Icon name="cross" />
				</button>

				<h2>Log In</h2>
				<p className="lead mb-4">Access your account to make purchases and download products you’ve already bought.</p>

				<form action={CharizURL.url("/auth/apple/login")} method="post">
					<input type="hidden" name="return_to" value={returnTo} />
					<button className="btn btn-primary login-button login-button-apple" type="submit">
						<Icon name="apple" />
						Sign in with Apple
					</button>
				</form>

				<form action={CharizURL.url("/auth/google/login")} method="post">
					<input type="hidden" name="return_to" value={returnTo} />
					<button className="btn btn-primary login-button login-button-google" type="submit">
						<Icon name="google-g-color" />
						Sign in with Google
					</button>
				</form>
			</div>
			<div className="modal-buttons d-none d-sm-flex">
				<button className="btn btn-primary" onClick={() => Dialog.hide(true)}>
					Cancel
				</button>
			</div>
		</Dialog>
	);
}
