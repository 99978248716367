function imageLoad(image: HTMLImageElement): Promise<void> {
	if (image.complete) {
		return Promise.resolve();
	}
	return (
		image.decode() ??
		new Promise((resolve, reject) => {
			image.addEventListener("load", () => resolve());
			image.addEventListener("error", () => reject());
		})
	);
}

const onNextFrame = (callback: () => void) => requestAnimationFrame?.(callback) || setTimeout(callback, 1);

const items = document.querySelectorAll("noscript.fade-on-load");
for (const item of items) {
	const element = item as HTMLDivElement;
	const newContainer = document.createElement("span");
	newContainer.classList.add("fade-on-load", "loading");
	newContainer.innerHTML = element.textContent!;
	const image = newContainer.querySelector("img")!;

	const thumb = element.dataset.thumb;
	if (thumb !== undefined) {
		// Clone the img element, have it load the full size image, and swap over to it once loaded.
		const fullImage = image.cloneNode(false) as HTMLImageElement;
		image.src = thumb;

		// Ignoring this warning, we’re intentionally not awaiting this.
		// eslint-disable-next-line unicorn/prefer-top-level-await
		(async function () {
			try {
				await imageLoad(fullImage);
				onNextFrame(() => image.replaceWith(fullImage));

				// Still need to do this in case the full image somehow loads faster than the thumbnail
				newContainer.classList.remove("loading");
				newContainer.classList.add("loaded");
			} catch {
				// Ignore
			}
		})();
	}

	// Ignoring this warning, we’re intentionally not awaiting this.
	// eslint-disable-next-line unicorn/prefer-top-level-await
	(async function () {
		try {
			await imageLoad(image);
		} catch {
			// Ignore
		}
		newContainer.classList.remove("loading");
		newContainer.classList.add("loaded");
	})();

	element.parentNode!.replaceChild(newContainer, element);
}
