import clsx from "clsx";
import type { SVGAttributes } from "react";

export interface BaseProps extends SVGAttributes<SVGSVGElement> {
	name: string;
	rightSpace?: boolean;
	baseClass?: string;
	svg?: string;
}

export interface Props extends SVGAttributes<SVGSVGElement> {
	name: string;
	rightSpace?: boolean;
}

export function BaseIcon({ name, rightSpace, className, baseClass, svg, children, ...props }: BaseProps) {
	return (
		<i className={clsx(baseClass ?? "icon", `icon-${name}`, rightSpace ? "icon-spaced" : null, className)} aria-hidden>
			{/* eslint-disable-next-line react/no-danger */}
			<svg {...(props as any)} dangerouslySetInnerHTML={svg ? { __html: svg } : undefined}>
				{children}
			</svg>
		</i>
	);
}

export default function Icon(props: Props) {
	const { name } = props;
	// TODO: This is kinda awful. <Icon /> probably isn’t meant to be in @chariz/components.
	// eslint-disable-next-line unicorn/prefer-module
	const svg = require(`../../app/public/img/symbols/${name}.svg`).default;
	return (
		<BaseIcon {...props}>
			{name === "chariz-flame-head"
				? (
						<defs>
							<linearGradient id="chariz-flame-gradient" x1="50%" y1="0%" x2="50%" y2="100%">
								<stop stopColor="#ff9500" offset="0%" />
								<stop stopColor="#ff5e3a" offset="100%" />
							</linearGradient>
						</defs>
					)
				: null}
			<use xlinkHref={svg.url} />
		</BaseIcon>
	);
}
