// noinspection JSDeprecatedSymbols
if (
	navigator.platform === "MacIntel" &&
	navigator.maxTouchPoints > 1 &&
	!("isActuallyIpad" in document.documentElement.dataset)
) {
	// Liar! You’re actually an iPad!
	await fetch("/auth/session/is-actually-ipad", {
		method: "post"
	});
	window.location.reload();
}

// We need this because apparently tsc doesn’t believe this is actually an ESM file if there are
// no imports/exports? lol
export {};
