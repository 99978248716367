const segments = $(".nav.nav-segment");
for (let i = 0; i < segments.length; i++) {
	const nav = segments.eq(i);
	nav.css("--nav-item-count", segments.find(".nav-item").length);

	// TODO: I can’t import the JQuery type for some reason…
	const updatePosition = (tab: any) => {
		nav
			.css("--nav-item-left", `${nav.scrollLeft() + tab.position().left}px`)
			.css("--nav-item-width", `${tab.outerWidth(true)}px`);
	};

	nav.find("[data-bs-toggle=tab]")
		.on("show.bs.tab", (event) => {
			const tab = $(event.target);
			let i = 0;
			const loop = () => {
				updatePosition(tab);
				if (i < 300 / 30) {
					requestAnimationFrame(loop);
				}
				i++;
			};
			loop();
		});

	updatePosition(nav.find("[data-bs-toggle=tab].active"));
}

if (segments.length > 0) {
	window.addEventListener(
		"resize",
		() => {
			segments.find("[data-bs-toggle=tab].active")
				.trigger("show.bs.tab");
		},
		{ passive: true }
	);
}
