const navbar = $(".navbar");
if (navbar.length > 0) {
	const backdrop = navbar.find(".navbar-backdrop");

	let isDark = false;
	const mediaQuery = matchMedia("(prefers-color-scheme: dark)");
	const handleColorSchemeChange = () => {
		isDark = mediaQuery.matches;
	};
	// noinspection JSDeprecatedSymbols
	mediaQuery.addListener(handleColorSchemeChange);
	handleColorSchemeChange();

	const html = $("html");
	const handleScroll = () => {
		if (navbar.hasClass("js-menu-open") || html.hasClass("has-dialog")) {
			return;
		}
		const progress = Math.min(1, Math.max(0, window.scrollY / 20));
		backdrop.css("opacity", progress);
		const breakpoint = isDark ? 0.9 : 0.5;
		if (progress > breakpoint) {
			navbar.addClass("js-scrolled")
				.removeClass("js-not-scrolled");
		} else {
			navbar.addClass("js-not-scrolled")
				.removeClass("js-scrolled");
		}
	};

	window.addEventListener("scroll", handleScroll, { passive: true });
	handleScroll();
}

const isReducedMotion = matchMedia("(prefers-reduced-motion)");

const dropdownContainer = $(".dropdown");
let hideTimeout: NodeJS.Timeout | null = null;
dropdownContainer
	.on("show.bs.dropdown", (e) => {
		if (hideTimeout !== null) {
			clearTimeout(hideTimeout);
			hideTimeout = null;
		}

		const dropdown = $(e.currentTarget)
			.find(".dropdown-menu");
		dropdown.removeClass("hiding")
			.css("height", "initial");
		if (!isReducedMotion.matches) {
			const height = dropdown.css("height")!;
			dropdown.css("height", "0");
			setTimeout(() => {
				dropdown.css("height", height);
			}, 1);
		}
	})
	.on("hide.bs.dropdown", (e) => {
		if (hideTimeout !== null) {
			return;
		}

		const dropdown = $(e.currentTarget)
			.find(".dropdown-menu");
		dropdown.addClass("hiding");
		if (!isReducedMotion.matches) {
			dropdown.css("height", "0");
		}

		hideTimeout = setTimeout(() => {
			dropdown.removeClass("hiding")
				.css("height", "initial");
			hideTimeout = null;
		}, 300);
	});
