import clsx from "clsx";
import { createFocusTrap, FocusTrap } from "focus-trap";
import { Component, HTMLAttributes, ReactElement } from "react";
import { render } from "react-dom";

interface Props extends HTMLAttributes<HTMLElement> {
	bottomSheet?: boolean;
	autoFocusSelector?: string;
}

export default class Dialog extends Component<Props, any> {
	private static currentDialog: HTMLDivElement | null = null;
	private static incomingDialog: HTMLDivElement | null = null;
	private static originalScrollTop = 0;
	private static focusTrap?: FocusTrap;

	static show(dialog: ReactElement) {
		this.hide(true, true);

		const mainContainer = document.querySelector(".main-container") as HTMLDivElement;
		this.originalScrollTop = document.documentElement.scrollTop;
		mainContainer.style.marginTop = `-${this.originalScrollTop}px`;

		const dialogContainer = document.createElement("div");
		document.body.append(dialogContainer);
		render(dialog, dialogContainer);

		const modal = dialogContainer.querySelector(".modal") as HTMLDivElement;
		const modalContent = dialogContainer.querySelector(".modal-content") as HTMLDivElement;
		const initialFocus = (modalContent.querySelector(modal.dataset.autoFocus ?? ".btn.btn-primary") || undefined) as
			| HTMLElement
			| undefined;
		this.focusTrap = createFocusTrap(modalContent, {
			initialFocus,
			clickOutsideDeactivates: true,
			onPostDeactivate() {
				Dialog.hide(true);
			}
		});
		this.focusTrap.activate();
	}

	static hide(animated: boolean, isReshowing: boolean = false) {
		const dialog = Dialog.currentDialog;
		if (dialog === null) {
			return;
		}

		Dialog.currentDialog = Dialog.incomingDialog;
		Dialog.incomingDialog = null;
		Dialog.focusTrap?.deactivate();
		Dialog.focusTrap = undefined;

		const classList = document.documentElement.classList;
		const completion = () => {
			(dialog?.parentNode as HTMLDivElement).remove();
			classList.remove("has-dialog", "has-bottom-sheet", "dialog-hiding");

			if (!isReshowing) {
				const mainContainer = document.querySelector(".main-container") as HTMLDivElement;
				mainContainer.style.marginTop = "";
				window.scrollTo({
					left: 0,
					top: this.originalScrollTop,
					behavior: "instant"
				});
			}
		};

		if (animated) {
			classList.add("dialog-hiding");
			dialog!.classList.add("hide");
			setTimeout(completion, 250);
		} else {
			completion();
		}
	}

	override componentDidMount() {
		const classList = document.documentElement.classList;
		classList.add("has-dialog");
		if (this.props.bottomSheet) {
			classList.add("has-bottom-sheet");
		}
	}

	override render() {
		const { className, bottomSheet, autoFocusSelector, children } = this.props;
		return (
			<div
				ref={(dialog) => {
					if (Dialog.currentDialog === null) {
						Dialog.currentDialog = dialog;
					} else {
						Dialog.incomingDialog = dialog;
					}
				}}>
				<div
					{...this.props}
					className={clsx("modal show", className, bottomSheet ? "modal-bottom-sheet" : null)}
					role="dialog"
					aria-modal="true"
					data-auto-focus={autoFocusSelector}>
					<div className="modal-dialog">
						<div className="modal-content">{children}</div>
					</div>
				</div>
				<div className="modal-backdrop show" />
			</div>
		);
	}
}
