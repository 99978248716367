import Dialog from "../shared/Dialog";
import Icon from "../shared/Icon";
import { fetcher } from "@chariz/utils";

declare global {
	interface Window {
		sentryInit?(): void;
	}
}

export function CookieConsentFooterButton() {
	return (
		<div className="footer-action-button">
			<button type="button" className="btn" onClick={() => Dialog.show(<CookieConsentDialog />)}>
				<Icon name="cookie" />
				Manage Cookie Consent
			</button>
		</div>
	);
}

async function doConsent(accept: boolean) {
	Dialog.hide(true);
	const oldCookieConsent = document.documentElement.dataset.cookieConsent;
	document.documentElement.dataset.cookieConsent = accept ? "true" : "false";

	await fetcher("post", "/auth/session/cookie-consent", { accept });

	if (accept) {
		// Initialise Sentry if available.
		window.sentryInit?.();
	} else if (oldCookieConsent === "true") {
		// Reload so Sentry is unloaded.
		location.reload();
	}
}

export function CookieConsentDialog() {
	const state = document.documentElement.dataset.cookieConsent === "true" ? "Accept All" : "Necessary Only";

	return (
		<Dialog bottomSheet id="cookie-consent-dialog" className="modal-buttons-long">
			<div className="modal-body">
				<p>We use cookies in accordance with our <a href="https://hashbang.productions/privacy-policy/" target="_blank" rel="noreferrer">Privacy Policy</a> to provide this service.</p>
				<p>Your current cookie preference is <strong>{state}</strong>.</p>
			</div>
			<div className="modal-buttons">
				<button className="btn btn-primary" onClick={() => doConsent(true)}>
					Accept All
				</button>
				<button className="btn btn-primary" onClick={() => doConsent(false)}>
					Necessary Only
				</button>
				<button className="btn" onClick={() => Dialog.hide(true)}>
					Cancel
				</button>
			</div>
		</Dialog>
	);
}
