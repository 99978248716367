const classList = document.documentElement.classList;
classList.add("js");

// OS sniff
if (
	/iPhone|iPod|iPad/.test(navigator.platform) ||
	(navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1)
) {
	classList.add("os-ios");
	// Remove class that may have been set on the server side due to iPad pretending to be macOS
	classList.remove("os-macos");
} else if (navigator.platform.startsWith("Mac")) {
	classList.add("os-macos");
}

export { };
