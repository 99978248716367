import { CookieConsentFooterButton } from "@chariz/components/main/CookieConsent";
import LoginDialog from "@chariz/components/main/LoginDialog";
import Dialog from "@chariz/components/shared/Dialog";
import { render } from "react-dom";
import "./global";
import "./utils/fastclick";

if (window.console && console.log) {
	console.log("%c🔥 Chariz", "font: bold large -apple-system, BlinkMacSystemFont, sans-serif;");
}

await import("./vendor");

$(() => {
	/* eslint-disable unicorn/prefer-module */
	require("./utils/fade-on-load");
	require("./utils/navbar");
	require("./utils/cookie-notice");
	require("./utils/nav-segment");
	require("./utils/timestamp");
	/* eslint-enable unicorn/prefer-module */

	$(".navbar-login-button")
		.on("click", (e) => {
			e.preventDefault();
			Dialog.show(<LoginDialog />);
		});

	const cookieConsentContainer = $(".footer-cookie-consent-container");
	if (cookieConsentContainer.length > 0) {
		render(<CookieConsentFooterButton />, cookieConsentContainer[0]!);
	}
});

const html = $("html");
const page = html.attr("data-page");

if (html.is(".minimal-layout")) {
	import("./pages/minimal");
}

if (page === "cydia-login-return") {
	import("./pages/cydia-login-return");
}

if (page === "home" || page === "buy" || page === "checkout") {
	// eslint-disable-next-line unicorn/prefer-module
	require("./utils/is-actually-ipad");
}

if (page === "home") {
	import("./pages/home");
}

if (page === "buy") {
	import("./pages/buy");
}

if (page === "account" || page === "wishlist") {
	import("./pages/account");
}

if (page === "checkout" || page === "account") {
	import("./pages/checkout");
}

if (page === "purchase") {
	import("./pages/purchase");
}
